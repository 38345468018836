// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-tools-15-vs-30-year-mortgage-calculator-js": () => import("./../../../src/pages/tools/15-vs-30-year-mortgage-calculator.js" /* webpackChunkName: "component---src-pages-tools-15-vs-30-year-mortgage-calculator-js" */),
  "component---src-pages-tools-algorand-staking-calculator-js": () => import("./../../../src/pages/tools/algorand-staking-calculator.js" /* webpackChunkName: "component---src-pages-tools-algorand-staking-calculator-js" */),
  "component---src-pages-tools-avalanche-staking-calculator-js": () => import("./../../../src/pages/tools/avalanche-staking-calculator.js" /* webpackChunkName: "component---src-pages-tools-avalanche-staking-calculator-js" */),
  "component---src-pages-tools-barista-fire-calc-js": () => import("./../../../src/pages/tools/barista-fire-calc.js" /* webpackChunkName: "component---src-pages-tools-barista-fire-calc-js" */),
  "component---src-pages-tools-cardano-staking-calculator-js": () => import("./../../../src/pages/tools/cardano-staking-calculator.js" /* webpackChunkName: "component---src-pages-tools-cardano-staking-calculator-js" */),
  "component---src-pages-tools-coast-fire-calc-js": () => import("./../../../src/pages/tools/coast-fire-calc.js" /* webpackChunkName: "component---src-pages-tools-coast-fire-calc-js" */),
  "component---src-pages-tools-coast-fire-jobs-database-js": () => import("./../../../src/pages/tools/coast-fire-jobs-database.js" /* webpackChunkName: "component---src-pages-tools-coast-fire-jobs-database-js" */),
  "component---src-pages-tools-coinbase-usdc-calculator-js": () => import("./../../../src/pages/tools/coinbase-usdc-calculator.js" /* webpackChunkName: "component---src-pages-tools-coinbase-usdc-calculator-js" */),
  "component---src-pages-tools-compound-interest-calculator-js": () => import("./../../../src/pages/tools/compound-interest-calculator.js" /* webpackChunkName: "component---src-pages-tools-compound-interest-calculator-js" */),
  "component---src-pages-tools-cosmos-staking-calculator-js": () => import("./../../../src/pages/tools/cosmos-staking-calculator.js" /* webpackChunkName: "component---src-pages-tools-cosmos-staking-calculator-js" */),
  "component---src-pages-tools-electric-car-savings-calc-js": () => import("./../../../src/pages/tools/electric-car-savings-calc.js" /* webpackChunkName: "component---src-pages-tools-electric-car-savings-calc-js" */),
  "component---src-pages-tools-emergency-fund-calculator-js": () => import("./../../../src/pages/tools/emergency-fund-calculator.js" /* webpackChunkName: "component---src-pages-tools-emergency-fund-calculator-js" */),
  "component---src-pages-tools-ethereum-staking-calculator-js": () => import("./../../../src/pages/tools/ethereum-staking-calculator.js" /* webpackChunkName: "component---src-pages-tools-ethereum-staking-calculator-js" */),
  "component---src-pages-tools-fat-fire-calculator-js": () => import("./../../../src/pages/tools/fat-fire-calculator.js" /* webpackChunkName: "component---src-pages-tools-fat-fire-calculator-js" */),
  "component---src-pages-tools-fire-calculator-js": () => import("./../../../src/pages/tools/fire-calculator.js" /* webpackChunkName: "component---src-pages-tools-fire-calculator-js" */),
  "component---src-pages-tools-fire-purchase-impact-calculator-js": () => import("./../../../src/pages/tools/fire-purchase-impact-calculator.js" /* webpackChunkName: "component---src-pages-tools-fire-purchase-impact-calculator-js" */),
  "component---src-pages-tools-fire-windfall-impact-calculator-js": () => import("./../../../src/pages/tools/fire-windfall-impact-calculator.js" /* webpackChunkName: "component---src-pages-tools-fire-windfall-impact-calculator-js" */),
  "component---src-pages-tools-flow-staking-calculator-js": () => import("./../../../src/pages/tools/flow-staking-calculator.js" /* webpackChunkName: "component---src-pages-tools-flow-staking-calculator-js" */),
  "component---src-pages-tools-gas-mileage-savings-calc-js": () => import("./../../../src/pages/tools/gas-mileage-savings-calc.js" /* webpackChunkName: "component---src-pages-tools-gas-mileage-savings-calc-js" */),
  "component---src-pages-tools-house-hacking-calculator-js": () => import("./../../../src/pages/tools/house-hacking-calculator.js" /* webpackChunkName: "component---src-pages-tools-house-hacking-calculator-js" */),
  "component---src-pages-tools-hybrid-vs-gas-savings-calculator-js": () => import("./../../../src/pages/tools/hybrid-vs-gas-savings-calculator.js" /* webpackChunkName: "component---src-pages-tools-hybrid-vs-gas-savings-calculator-js" */),
  "component---src-pages-tools-js": () => import("./../../../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */),
  "component---src-pages-tools-kava-staking-calculator-js": () => import("./../../../src/pages/tools/kava-staking-calculator.js" /* webpackChunkName: "component---src-pages-tools-kava-staking-calculator-js" */),
  "component---src-pages-tools-keeping-up-with-inflation-calculator-js": () => import("./../../../src/pages/tools/keeping-up-with-inflation-calculator.js" /* webpackChunkName: "component---src-pages-tools-keeping-up-with-inflation-calculator-js" */),
  "component---src-pages-tools-kraken-polkadot-js": () => import("./../../../src/pages/tools/kraken-polkadot.js" /* webpackChunkName: "component---src-pages-tools-kraken-polkadot-js" */),
  "component---src-pages-tools-kusama-staking-calculator-js": () => import("./../../../src/pages/tools/kusama-staking-calculator.js" /* webpackChunkName: "component---src-pages-tools-kusama-staking-calculator-js" */),
  "component---src-pages-tools-loan-calculator-js": () => import("./../../../src/pages/tools/loan-calculator.js" /* webpackChunkName: "component---src-pages-tools-loan-calculator-js" */),
  "component---src-pages-tools-portfolio-rebalancing-calc-js": () => import("./../../../src/pages/tools/portfolio-rebalancing-calc.js" /* webpackChunkName: "component---src-pages-tools-portfolio-rebalancing-calc-js" */),
  "component---src-pages-tools-rental-property-calculator-js": () => import("./../../../src/pages/tools/rental-property-calculator.js" /* webpackChunkName: "component---src-pages-tools-rental-property-calculator-js" */),
  "component---src-pages-tools-savings-rate-calculator-js": () => import("./../../../src/pages/tools/savings-rate-calculator.js" /* webpackChunkName: "component---src-pages-tools-savings-rate-calculator-js" */),
  "component---src-pages-tools-solana-staking-calculator-js": () => import("./../../../src/pages/tools/solana-staking-calculator.js" /* webpackChunkName: "component---src-pages-tools-solana-staking-calculator-js" */),
  "component---src-pages-tools-tezos-staking-calculator-js": () => import("./../../../src/pages/tools/tezos-staking-calculator.js" /* webpackChunkName: "component---src-pages-tools-tezos-staking-calculator-js" */),
  "component---src-pages-tools-upwork-fee-calculator-js": () => import("./../../../src/pages/tools/upwork-fee-calculator.js" /* webpackChunkName: "component---src-pages-tools-upwork-fee-calculator-js" */),
  "component---src-pages-tools-usdc-calculator-js": () => import("./../../../src/pages/tools/usdc-calculator.js" /* webpackChunkName: "component---src-pages-tools-usdc-calculator-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

